import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {JwtService} from '../services/jwt.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};

    if (req.url.includes(environment.api_url)) {
      const token = this.jwtService.getToken();

      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`;
      }
    }

    const request = req.clone({setHeaders: headersConfig});
    return next.handle(request);
  }
}
