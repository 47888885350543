import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CropperComponent} from "angular-cropperjs";
import {ModalController, Platform} from '@ionic/angular';

@Component({
  selector: 'image-cropper-modal',
  templateUrl: 'image-cropper-modal.html',
  styleUrls: ['./image-cropper-modal.scss'],
})
export class ImageCropperModalComponent implements OnInit{
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  @Input() image: string;
  @Input() ratio: number;

  cropperOptions;

  constructor(private modalCtrl: ModalController,
              private platform: Platform) {
  }

  ngOnInit() {
    this.cropperOptions = {
      dragMode: 'crop',
      aspectRatio: this.ratio ? this.ratio : 1,
      autoCrop: true,
      movable: true,
      zoomable: true,
      scalable: true,
      autoCropArea: 0.8,
      responsive: true,
      minCanvasWidth: this.platform.width() > 400 ? 400 : this.platform.width(),
      minContainerWidth: this.platform.width() > 600 ? 600 : this.platform.width(),
      minContainerHeight: 400,
    };
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  finishCrop() {
    this.modalCtrl.dismiss(this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg', (100 / 100)));
  }

  cropperTouchStart(event) {
    event.stopPropagation();
    event.preventDefault();
  }

}
