import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ImageCropperModalComponent} from './image-cropper-modal';
import {AngularCropperjsModule} from 'angular-cropperjs';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    AngularCropperjsModule
  ],
  declarations: [
    ImageCropperModalComponent
  ],
  exports: [
    ImageCropperModalComponent
  ]
})
export class ImageCropperModalModule {
}
