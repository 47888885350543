import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';

import {Platform, ToastController} from '@ionic/angular';

import {StatusBar} from '@capacitor/status-bar';
import {SplashScreen} from '@capacitor/splash-screen';

import {UserService} from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  dark = false;

  constructor(
    private platform: Platform,
    private router: Router,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        SplashScreen.hide();
      }
      this.userService.populate();
    });

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Atualização disponível!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Recarregar'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  onDarkChange(dark) {
    this.dark = dark;
  }
}
