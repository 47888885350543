import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject, ReplaySubject} from 'rxjs';

import {ApiService} from './api.service';
import {JwtService} from './jwt.service';
import {map, distinctUntilChanged, finalize} from 'rxjs/operators';
import {FcmService} from './fcm.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<any>({} as any);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private fcmService: FcmService
  ) {
  }

  populate() {
    if (this.jwtService.getToken()) {
      // this.apiService.get('/v1/profile')
      //   .subscribe(
      //     data => this.setAuth(data),
      //     err => this.purgeAuth()
      //   );
      this.currentUserSubject.next({});
      this.isAuthenticatedSubject.next(true);
      this.fcmService.startNotificationProcess();
    } else {
      this.purgeAuth();
    }
  }

  attemptAuth(credentials): Observable<any> {
    return this.apiService.post(`/v1/login`, credentials)
      .pipe(map(
        loginResponse => {
          this.setAuth(loginResponse);
          return loginResponse;
        }
      ));
  }

  logout(): Observable<any> {
    return this.apiService.post(`/v1/logout`, {})
      .pipe(finalize(() => {
        this.purgeAuth();
      }));
  }

  setAuth(loginResponse: any) {
    if (loginResponse.access_token) {
      this.jwtService.saveToken(loginResponse.access_token);
    }
    this.populate();
  }

  purgeAuth() {
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as any);
    this.isAuthenticatedSubject.next(false);
  }

  getCurrentUser(): any {
    return this.currentUserSubject.value;
  }

  register({body, endpoint}): Observable<any> {
    return this.apiService.post('/v1/' + endpoint, body);
  }

  setUserPicture(file) {
    const formData = new FormData();
    formData.append('image', file);
    return this.apiService.post('/v1/profile/photo', formData);
  }

  async getUserPicture() {
    try {
      const {data} = await this.apiService.get('/v1/profile/photo').toPromise();
      return data.url;
    } catch (e) {
      return 'https://www.gravatar.com/avatar?d=mm&s=140';
    }
  }
}
