import {NgModule} from '@angular/core';
import {AuthGuard} from './services/auth-guard.service';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'specialists',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/specialist-list/specialist-list.module').then(m => m.SpecialistListModule)
      },
    ],
  },
  {
    path: 'schedule',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: ':scheduleId',
        loadChildren: () => import('./pages/schedule-meet/schedule-meet.module').then(m => m.ScheduleMeetPageModule)
      }
    ]
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'schedule-meet',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/schedule-meet/schedule-meet.module').then(m => m.ScheduleMeetPageModule)
  },
  {
    path: 'patient-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/patient-list/patient-list.module').then( m => m.PatientListPageModule)
  },
  {
    path: 'search-establishment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/search-establishment/search-establishment.module').then( m => m.SearchEstablishmentModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
