import {Injectable} from '@angular/core';
import {ActionPerformed, PushNotificationSchema, PushNotifications, Token,} from '@capacitor/push-notifications';
import {Platform} from '@ionic/angular';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(private platform: Platform,
              private apiService: ApiService) {
  }

  startNotificationProcess() {
    if (this.platform.is('hybrid')) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          this.apiService.post('/v1/user/fcm_token', token).subscribe(response => {
            console.log("Response", response);
          }, error => {
            console.log("Error", error);
          });
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration:', JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received:', notification);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed:', notification);
        }
      );
    }
  }
}
