import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {IonicModule} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FormsModule} from '@angular/forms';
import localePT from '@angular/common/locales/pt';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HttpTokenInterceptor} from './interceptors/http.token.interceptor';
import {SideMenuModule} from './components/side-menu/side-menu.module';
import {SimpleMaskModule} from 'ngx-ion-simple-mask';
import {ImageCropperModalModule} from './components/image-cropper-modal/image-cropper-modal.module';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    SideMenuModule,
    SimpleMaskModule,
    ImageCropperModalModule
  ],
  declarations: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    DatePipe,
    Camera
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

registerLocaleData(localePT, 'pt-BR');
