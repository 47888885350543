import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../services/user.service';
import {AlertController} from '@ionic/angular';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  @Input('dark') dark: boolean;
  @Output('onDarkChangeEvent') onDarkChangeEvent = new EventEmitter<boolean>();

  isAuthenticated: boolean;
  appPages = [];

  constructor(private userService: UserService,
              private alertCtrl: AlertController,
              private router: Router) {
  }

  ngOnInit() {
    this.userService.isAuthenticated.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
        this.mountPages();
      }
    );
  }

  mountPages() {
    this.appPages = [
      {
        title: 'Início',
        url: '/home',
        icon: 'home'
      },
      // {
      //   title: 'Especialistas',
      //   url: '/specialists',
      //   icon: 'people'
      // },
      {
        title: 'Agenda',
        url: '/schedule',
        icon: 'calendar',
        hidden: !this.isAuthenticated
      },
      {
        title: 'Pacientes',
        url: '/patient-list',
        icon: 'people',
        hidden: !this.isAuthenticated
      },
      {
        title: 'Cadastro',
        url: '/signup',
        icon: 'person-add',
        hidden: !this.isAuthenticated
      },
      {
        title: 'Buscar Estabelecimento',
        url: '/search-establishment',
        icon: 'search',
        hidden: !this.isAuthenticated
      }
    ];
  }

  async logout() {
    const alert = await this.alertCtrl.create({
      header: 'Sair',
      message: 'Tem certeza que deseja sair?',
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      }, {
        text: 'Sair',
        handler: async _ => {
          this.userService.logout()
            .pipe(finalize(() => {
              this.router.navigateByUrl('/');
            })).subscribe();
        }
      }]
    });
    await alert.present();
  }

  onDarkChange() {
    this.onDarkChangeEvent.emit(this.dark);
  }
}
