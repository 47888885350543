import { NgModule } from '@angular/core';
import {SideMenuComponent} from './side-menu.component';
import {IonicModule} from '@ionic/angular';
import { RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    IonicModule,
    RouterModule,
    CommonModule,
    FormsModule
  ],
  declarations: [
    SideMenuComponent
  ],
  exports: [
    SideMenuComponent
  ]
})
export class SideMenuModule {}
